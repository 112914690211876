.row-hidden {
  color: $base_2;

  a {
    color: $base_2;
  }
}
.row-muted {
  color: $base_2;

  a {
    color: $base_2;
  }
}
.row-unactive {
  @extend .row-muted;
  text-decoration: line-through;
}

.row-archived {
  color: $base_3;
}