@import 'variables';

@import '~bootstrap/scss/bootstrap';
@import '~font-awesome';
@import '~selectize/dist/css/selectize.bootstrap3.css';
@import '~flatpickr/dist/flatpickr.min.css';
@import '~highlight.js/styles/github.css';

@import 'header';
@import 'table';
@import 'form';
@import 'viewer';
@import 'popover';

body {
    font-size: .875rem;
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}
.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}
.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}
.sidebar .nav-link .fa {
    margin-right: 4px;
    color: $base_2;
}
.sidebar .nav-link.active {
    color: #007bff;
}

[role="main"] {
    padding-top: 48px;
}

.trig-unconfirm {
    cursor: pointer;
}
