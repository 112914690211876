.bsm-form-wrapper {
  margin-bottom: 10px;
  padding: 10px;
  background-color: $base_1;

  form {
    margin-bottom: 0;
  }
}

.form-control.loading {
  .selectize-input {
    color: #ccc;
    background-color: #f1f1f1;
  }
}

.required:after {
  content: '*';
}