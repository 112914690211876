.navbar .navbar-brand,
.navbar-nav .nav-link {
  color: $white;
}

.navbar .navbar-text {
  color: #c5e5b0;
}

h1 i {
  color: $base_4;
}